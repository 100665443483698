import logoDark from "@/assets/logo_square_dark.png";
import logoLight from "@/assets/logo_square_light.png";
import { useTheme } from "@/hooks/useTheme";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

export const SquareSiteLogo = ({ className, linkTo = null }) => {
  const { theme } = useTheme();

  return linkTo ? (
    <Link to={linkTo}>
      <img
        src={theme === "dark" ? logoDark : logoLight}
        className={cn("aspect-square", className)}
        alt="logo"
      />
    </Link>
  ) : (
    <img
      src={theme === "dark" ? logoDark : logoLight}
      className={cn("aspect-square cursor-pointer", className)}
      alt="logo"
    />
  );
};
